import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
//import IconButton from '@material-ui/core/IconButton';
//import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
//import GridList from '@material-ui/core/GridList';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
//import Markdown from './Markdown';
//import post1 from './blog-post.1.md';
//import post2 from './blog-post.2.md';
//import post3 from './blog-post.3.md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Redirect } from 'react-router-dom';

import {define_event_handler, emit_data, remove_event} from "./api";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { Waypoint } from 'react-waypoint';

import MessageIcon from '@material-ui/icons/Message';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'universal-cookie';
import InputBase from '@material-ui/core/InputBase';

import Instagram from 'mdi-material-ui/Instagram';

import FacebookBox from 'mdi-material-ui/FacebookBox';

import Email from '@material-ui/icons/Email';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

const cookies = new Cookies();
var cookie_id = cookies.get('id');
if (cookie_id == undefined) {
  var new_id = makeid(10);
  cookies.set('id', new_id, { path: '/', maxAge: 315360000 });
  cookie_id = new_id;
}
console.log('id: ' + cookie_id); // Pacman
emit_data('id', cookie_id);

const sections = [
  {name: 'Things',    url: '/'},
  {name: 'Gallery', url: '/gallery'},
  {name: 'FAQ',     url: '/faq'},
];

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //[theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
    [theme.breakpoints.up('sm')]: {
      //width: 1100,
      //marginLeft: 'auto',
      //marginRight: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
    },
  },
  toolbarMain: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'center',
  },
  mainFeaturedPost: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 4,
  },
  mainFeaturedPostContent: {
    padding: `${theme.spacing.unit * 6}px`,
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  mainGrid: {
    marginTop: theme.spacing.unit * 3,
  },
  card: {
   //display: 'flex',
   position: 'relative',
  },/*
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },*/
  markdown: {
    padding: `${theme.spacing.unit * 3}px 0`,
  },
  sidebarAboutBox: {
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing.unit * 3,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit * 6}px 0`,
  },

  media: {
      height: 0,
      paddingTop: '20%' // 16:9
  },
  /*
  card: {
    position: 'relative',
  },
  */
  overlay: {
      position: 'absolute',
      top: '25px',
      left: '25px',
      color: 'black',
      backgroundColor: 'white'
  },
  /*
  overlay_test: {
     position: 'absolute',
     top: '5px',
     left: '20px',
     color: 'white',
     [theme.breakpoints.down('sm')]: {
       fontSize: '25px',
     },
     [theme.breakpoints.up('md')]: {
       fontSize: '50px',
     },
     //fontSize: '50px',
     textShadow: '3px 2px black',
  },
  */
  root: {
    /*
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    */
  },
  GridRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    //width: '100%',
    height: 350,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 4,
      padding: theme.spacing.unit * 3,
    },
  },
});

const so_styles = {
  media: {
    paddingTop: '20%' // 16:9
  },
  media_ig: {
    //height: 0,
    //objectFit: 'cover',
    paddingTop: '100%',
    //width: 160,
  },
  card: {
    position: 'relative',
    /*
    height: '300px',
    backgroundImage: 'url("./images/house2.jpg")',
    backgroundSize: '100% auto',
    backgroundPosition: 'top bottom',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'gray',
    */
    //boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.2)',
    //background: 'rgba(39,62,84,0.82)',
  },
   overlay: {
      position: 'absolute',
      top: '5px',
      left: '20px',
      color: 'white',
      fontSize: '50px',
      textShadow: '3px 2px black',
   },
   overlay_xs: {
      position: 'absolute',
      top: '2px',
      left: '10px',
      color: 'white',
      fontSize: '35px',
      textShadow: '3px 2px black',
   },
   overlay_right: {
      position: 'absolute',
      top: '5px',
      right: '20px',
      color: 'lime',
      fontSize: '20px',
      textShadow: '2px 1px black',
   },
   overlay_p: {
      position: 'absolute',
      top: '80px',
      left: '10px',
      color: 'white',
      fontSize: '18px',
      textShadow: '3px 2px black',
   },
   center: {
      position: 'absolute', left: '46%', top: '46%',
      //transform: 'translate(-50%, -50%)'
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
}

function Loading(props) {
  if (props.feed.length == 0) {
    return <CircularProgress size={100} style={so_styles.center} />
  }
  else {
    return null;
  }
}

class Faq extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const faq_content = [
      ["How can I find prices and make reservations?", "You can use the chat button in menu to " +
      "instantly chat with us, clarify anything and arrange reservations"],
      ["How do I get to Veyvah Island?", "It is most accessible by speedboat ferry. " +
      "Malé to Veyvah on Saturdays, Mondays, Tuesdays and Thursdays. If you like sea plane " +
      "transfer better, that's possible as well. We'll arrange this for you."],
    ];
    return (
      <div>
      {faq_content.map(qa => (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{fontSize: 16}}>
                {qa[0]}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              {qa[1]}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      </div>
    )
  }
  componentDidMount() {
    document.title = "FAQ - Veyvah Inn";
    this.props.setCurrentSection("FAQ");
    emit_data('route', 'FAQ');
  }
}

class EndOfFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.feed_requested = false;
  }

  render() {
    return(
    <Waypoint onEnter={this._handleWaypointEnter()}>
    </Waypoint>
    )
  }

  _handleWaypointEnter() {
    if(!this.props.isMounted) {
      console.log('getting data..');
      //console.log('isLoading: '+this.state.isLoading);
      emit_data('get feed', true);
      this.feed_requested = true;
    }
    else {
      console.log('gallery not mounted');
    }
  }
}

class FeedTile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {waypoint_triggered: false}
  }

  render() {
    if(this.state.waypoint_triggered) {
      if(this.props.type == 'image') {
        return(
          <GridListTile key={this.props.media['file']} cols={1}>
            <img src={'https://veyvah.in/instagram-feed/' + this.props.media['file']} />
          </GridListTile>
        )
      }
      else {
        return(
          <GridListTile key={this.props.media['file']} cols={1}>
            <video width={this.props.media['width']} height={this.props.media['height']} poster={'https://veyvah.in/instagram-feed/' + this.props.media['poster']} autoPlay loop muted>
              <source src={'https://veyvah.in/instagram-feed/' + this.props.media['file']} type="video/mp4"></source>
            </video>
          </GridListTile>
        )
      }
    }
    else {
      return(
        <Waypoint onEnter={this._handleWaypointEnter()}>
        </Waypoint>
      )
    }
  }

  _handleWaypointEnter() {
      console.log(this.props.media['file'] + ' triggered');
      this.setState({waypoint_triggered: true});
  }
}

function Feed(props) {

  const getGridListCols = () => {
    if (isWidthUp('xl', props.width)) {return 4;}
    if (isWidthUp('lg', props.width)) {return 3;}
    if (isWidthUp('md', props.width)) {return 3;}
    if (isWidthUp('sm', props.width)) {return 2;}
    return 1;
  }

  //console.log('Feed: ' + JSON.stringify(props.feed));
  if(props.feed.length == 0) {console.log('Empty feed!'); return null;}
  else return (
    <div>
    {/*<p>feed data: {JSON.stringify(this.state.feed)}</p>*/}
    <div className={props.classes.root}>
    <GridList cellHeight={'auto'}
      style={{backgroundColor: 'white', height: 700}} cols={getGridListCols()}>
      {props.feed.map(function(post) {
        //var tiles;

        const images = post.images.map(image => (
          <GridListTile key={image['file']} cols={1}>
            <img src={'https://veyvah.in/instagram-feed/' + image['file']} />
          </GridListTile>
        ));

        const videos = post.videos.map(video => (
          <GridListTile key={video['file']} cols={1}>
            <video width={video['width']} height={video['height']} poster={'https://veyvah.in/instagram-feed/' + video['poster']} autoPlay loop muted>
              <source src={'https://veyvah.in/instagram-feed/' + video['file']} type="video/mp4"></source>
            </video>
          </GridListTile>
        ));

        //console.log('tiles: ');
        return ([images, videos])
      })}
      <Waypoint onEnter={props.waypointHandler} />
    </GridList>
    {/*}<EndOfFeed isMounted={props.isMounted} />*/}
    </div>
  </div>
  )
}

class Gallery extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    console.log('this.props: ', this.props);
    const { classes } = this.props.classes;
    console.log('width: ', this.props.width);
    this.persons = [];
    this.state = {feed: [], redirect: false}
    this.didFethFeed = false;
  }

  render() {

    const getGridListCols = () => {
      if (isWidthUp('xl', this.props.width)) {return 4;}
      if (isWidthUp('lg', this.props.width)) {return 3;}
      if (isWidthUp('md', this.props.width)) {return 3;}
      if (isWidthUp('sm', this.props.width)) {return 2;}
      return 1;
    }

    console.log('state0: ' + this.state);

    return (
      <div>
      {/*<p>feed data: {JSON.stringify(this.state.feed)}</p>*/}
      {/*}<p>a<br />b<br />c<br />d<br />b<br />b<br />b<br />b<br />b<br />b<br />b<br />b</p>*/}
      <Loading feed={this.state.feed} />
      <Feed classes={this.props.classes} feed={this.state.feed} width={this.props.width} isMounted={this._isMounted} waypointHandler={this._handleWaypointEnter} />
      <RedirectTo redirect={this.state.redirect} to="/" />
      {/*
      <Waypoint key={1} onEnter={this._handleWaypointEnter(this.state.feed)}>
      </Waypoint>

      <Waypoint key={2} onEnter={this._handleEndReached()}></Waypoint>
      */}
    </div>
    )
  }

  _handleWaypointEnter = () => {
    console.log("Waypoint entered!");
    setTimeout(() => {this.setState({"redirect": true})}, 1500);
  }

  _handleEndReached()
  {
    console.log('end reached!')
  }

  _handleWaypointEnter(feed)
  {
    console.log('waypoint entered!');
    //console.log('feed: ' + feed);

    if(feed.length == 0) {
      console.log('feed empty, getting data..');
      emit_data('get feed', true);
      this.didFethFeed = true;
    }
    else {
      var newFeed = feed;
      console.log('feed len: ' + feed.length);
      newFeed.push({id: "1936360082365199589_7518662342", created_time: 1545052178, likes: 53, images: [{width: 320, height: 399, file: "47583740_276490733059014_2981219750441001107_n.jpg"}], videos: []});
      console.log('newFeed len: ' + newFeed.length);
      //this.setState({feed: newFeed});
    }
  }

  componentDidMount()
  {
    document.title = "Gallery - Veyvah Inn";
    this.props.setCurrentSection("Gallery");
    console.log('width: ', this.props.width);
    this._isMounted = true;
    /*
    axios.get('https://api.instagram.com/v1/users/self/media/recent/?access_token=7518662341.3fba0e1.2bff21dc86e745659f1a58d8d46120d5')//('https://api.instagram.com/v1/users/self/?access_token=8558529685.3fba0e1.677e65a7a91746afa0519824647fdfb6')//(`https://jsonplaceholder.typicode.com/users`)
    .then(res => {
        const data = res.data.data;
        //console.log('data: ', data);
        this.setState({feed: data});
        //console.log('this.state.feed: ', JSON.stringify(this.state.feed));
      })
   */
   define_event_handler('feed data', (data) => {
      //console.log('feed data: ', data);
      console.log('this._isMounted:', this._isMounted);
      if (this._isMounted) {
        this.setState({feed: data});
        this.didFethFeed = false;
      }
    });
    emit_data('get feed', true);
    emit_data('route', 'Gallery');
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_event('feed data');
  }

}

class Blog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const getOverlayStyle = () => {
      if (isWidthUp('sm', this.props.width)) {return so_styles.overlay;}
      return so_styles.overlay_xs;
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div>
          <main>
            {/* Main featured post */}
            <Grid container spacing={40}>
              {featuredPosts.map(post => (
                <Grid item key={post.title} xs={12} md={6} lg={4}>
                  <Card>
                    <CardActionArea component={Link} to={post.url}>
                    <div>
                      <CardMedia image={post.photo} style={so_styles.media}/>
                      <CardContent>
                        <Typography style={getOverlayStyle()}>
                          {post.title}
                        </Typography>
                        <Typography variant="subtitle1">
                          {post.description}
                          <span style={{color: 'green'}}> Starting from ${post.price}.</span>
                          <span style={{color: 'blue'}}> Show more</span>
                        </Typography>
                      </CardContent>
                    </div>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {/* End sub featured posts */}
          </main>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    document.title = "Veyvah Inn Maldives | Budget Guesthouse and Excursions";
    this.props.setCurrentSection("Things");
    emit_data('route', 'Things');
  }
}

function RedirectTo(props) {
  if(props.redirect) return (
    <Redirect to={props.to} />
  )
  else return null;
}

class ChatDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {chatOpen: false, messages: [],
      messageEventHandlerInitiated: false, messageValue: ""}
  }
  componentWillReceiveProps(newProps) {
    this.setState({chatOpen: newProps.chatOpen});
  }
  handleChatOpen = () => {
    this.setState({chatOpen: true});
  }
  handleChatClose = () => {
    this.setState({chatOpen: false});
  }
  handleChatSend = () => {
    emit_data('chat', this.state.messageValue);
    var messages = this.state.messages;
    messages.push({'from':'You', 'message':this.state.messageValue});
    this.setState({messages: messages, messageValue: ""});
  }
  setMessageValue = (event) => {
    this.setState({messageValue: event.target.value});
  }
  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleChatSend();
    }
  }
  handleBookButton = () => {
    var message = "I'm interested to book"
    emit_data('chat', message);
    var messages = this.state.messages;
    messages.push({'from':'You', 'message':message});
    this.setState({messages: messages, chatOpen: true});
  }
  handleInstagramClick = () => {
    emit_data('route', 'Instagram');
    var win = window.open('https://www.instagram.com/holiday_inn_veyvah_maldives/', '_blank');
    win.focus();
  }
  handleFacebookClick = () => {
    emit_data('route', 'Facebook');
    var win = window.open('https://www.facebook.com/veyvah', '_blank');
    win.focus();
  }
  render() {
    return (
    <div>
    <Dialog open={this.state.chatOpen} onClose={this.handleChatClose} aria-labelledby="chat-dialog-title" >
      <DialogTitle id="chat-dialog-title"><span style={{fontSize: "16px"}}>Chat with <a href="mailto:team@veyvah.in">team@veyvah.in</a></span></DialogTitle>
      <DialogContent>
        {/*}
        <TextField
          onChange={this.setMessageValue}
          autoFocus
          margin="dense"
          id="name"
          label="Message"
          type="text"
          fullWidth
        />
        */}
        <Typography component="p">

        </Typography>
        {this.state.messages.map((message, index) => (<Typography key={index} component="p" style={{}}>{message['from']}: {message['message']}</Typography>))}
        <Paper>
          <InputBase autoFocus placeholder="Message" value={this.state.messageValue} onChange={this.setMessageValue} onKeyPress={this.handleKeyPress} />
        </Paper>
        <Button onClick={this.handleChatSend} color="primary" variant="contained" fullWidth>Send</Button>
        {/*}
        <DialogActions>
          <Button onClick={this.handleChatSend} color="primary">Send</Button>
        </DialogActions>
        */}
      </DialogContent>

    </Dialog>
    <Grid container justify="center" style={{padding: '10px'}}>
      <Button key={'book_button'} variant="contained" size="large" color="primary"
        fullWidth={false} style={{marginRight: '10px'}} onClick={this.handleBookButton}>
          Book
      </Button>
      <Button key={'email_button'} href="mailto:team@veyvah.in" size="large" color="inherit" style={{marginRight: '5px'}}>
        <Email />
      </Button>
      <Button key={'instagram_button'} onClick={this.handleInstagramClick} size="large" color="inherit" style={{marginRight: '5px'}}>
        <Instagram />
      </Button>
      <Button key={'facebook_button'} onClick={this.handleFacebookClick} size="large" color="inherit" style={{marginRight: '5px'}}>
        <FacebookBox />
      </Button>
    </Grid>
    </div>
    )
  }
  componentDidMount() {
    if(!this.state.messageEventHandlerInitiated) {
      console.log('initiating message event handler');
      define_event_handler('chat', (message => {
        console.log('message: ' + JSON.stringify(message));
        var messages = this.state.messages;
        //const message_decoded = JSON.parse(message);
        messages.push({'from':message['from'], 'message':message['message']});
        this.setState({messages: messages, chatOpen: true});
      }));
      this.setState({messageEventHandlerInitiated: true});
    }
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleBookButton = () => {

  }

  render() {
    return (
      <footer style={{justifyContent: 'center'}}>
        <Typography variant="h6" align="center" gutterBottom>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
        </Typography>
      </footer>
    );
  }
}

class Index extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = this.props.classes;
    this.state = {chatOpen: false, currentSection: "Things"}
  }
  handleChatOpen = () => {
    this.setState({chatOpen: true});
  }
  setCurrentSection = (name) => {
    this.setState({currentSection: name});
  }
  render() {
    const getSectionButtonVariant = (name) => {
      if(this.state.currentSection == name) return "outlined";
      else return "text";
    }
  return (
    <div className={this.props.classes.layout}>
    <Router>
          <Toolbar variant="dense" className={this.props.classes.toolbarSecondary}>
            {sections.map(section => (
              <Button key={section.name} color="inherit" variant={getSectionButtonVariant(section.name)} component={Link} to={section.url}>
                {section.name}
              </Button>
            ))}
          <Button key={'chat'} color="inherit" onClick={this.handleChatOpen}>
            <MessageIcon />
          </Button>
          </Toolbar>
          <Route path="/" exact render={() => <Blog width={this.props.width} classes={this.props.classes} setCurrentSection={this.setCurrentSection} />} />
          <Route path="/gallery" render={() => <Gallery width={this.props.width} classes={this.props.classes} setCurrentSection={this.setCurrentSection} />} />
          <Route path="/faq" render={() => <Faq setCurrentSection={this.setCurrentSection} />} />
          <Route path="/inn" render={() => <Inn width={this.props.width} classes={this.props.classes} />} />
          <Route path="/snorkeling" render={() => <NoContent thing="Snorkeling" />} />
          <Route path="/surfing" render={() => <NoContent thing="Surfing" />} />
          <Route path="/sandbank" render={() => <NoContent thing="Sandbank" />} />
          <Route path="/fishing" render={() => <NoContent thing="Fishing" />} />
          <Route path="/dolphin" render={() => <NoContent thing="Dolphin" />} />
          <Route path="/picnic" render={() => <NoContent thing="Picnic" />} />
          <Route path="/dinner" render={() => <NoContent thing="Dinner" />} />
        </Router>
        <ChatDialog chatOpen={this.state.chatOpen} />
        <Footer />
    </div>
  )
  }
}

class Inn extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = this.props.classes;
  }
  render() {
    const inn_images = [
      '188291709_small.jpg', '185783326_small.jpg', '185783332_small.jpg',
      '188291812_small.jpg', '185783352_small.jpg', '185783358_small.jpg'
    ];
    const getGridListCols = () => {
      if (isWidthUp('sm', this.props.width)) {return 2;}
      return 1;
    }
    const getGridListWidth = () => {
      if (isWidthUp('sm', this.props.width)) {return '100%';}
      return 300;
    }
    return (
      <Grid container>
      <Paper className={this.props.classes.paper}>
      <Typography component="h1" variant="h4" align="center" style={{margin: '2%'}}>
        The Guest House
      </Typography>
      <Grid container>
      <Grid item lg={6}>
      <div className={this.props.classes.GridRoot}>
        <GridList className={this.props.classes.gridList} style={{marginBottom:'3%', width:getGridListWidth()}} cellHeight={300} cols={getGridListCols()}>
          {inn_images.map(file => (
            <GridListTile key={file}>
              <img src={'images/inn/'+file} />
            </GridListTile>
          ))}
        </GridList>
      </div>
      </Grid>
      <Grid item lg={6}>
        <div style={{margin:20}}>
        {featuredPosts[0].body.map(paragraph => (
          <Typography variant="subtitle1" paragraph style={{fontSize: 16}}>
            {paragraph}
          </Typography>
        ))}
        </div>
      </Grid>
      </Grid>
      </Paper>
      </Grid>
    );
  }
  componentDidMount() {
    document.title = "Guest House - Veyvah Inn";
    emit_data('route', 'Inn');
  }
}

class NoContent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Typography variant="h5">
        No content yet. Check back later.
      </Typography>
    )
  }
  componentDidMount() {
    emit_data('route', this.props.thing);
  }
}

export default compose(withStyles(styles), withWidth())(Index);

const featuredPosts = [
  {
    title: 'Veyvah Inn',
    url: '/inn',
    photo: "./images/inn5.jpg",
    description: 'Located in the beautiful and scenic Meemu  Atoll Veyvah Island, it is an ideal, budget guest house for tourists to relax and have a vacation. Island populations is around 300. Staying here is wonderful and people living here are  friendly and kind hearted. The guesthouse consists of 3 spacious bed rooms with attached bathrooms. All rooms are air conditioned. Both Maldivian and European Foods are served at the request of the client, both indoor and outdoor dining.',
    price: 45,
    //body: ["Providing free WiFi, Veyvah Inn Maldives is located in Meemu Atoll. With a private beach area, the property also has free bikes, as well as a garden. The property provides a shared lounge.", "At the guesthouse, the rooms come with a closet. Featuring a private bathroom with a bath and free toiletries, certain rooms at Veyvah Inn Maldives also feature a garden view. At the accommodation each room has air conditioning and a flat-screen TV.", "Guests at Veyvah Inn Maldives can enjoy a continental or an American breakfast.", "Staff at the reception can help guests with any questions that they might have."],
    body: ["Located in Meemu Atoll, Veyvah Inn Maldives features free bikes, garden, shared lounge, and free WiFi. The property provides a 24-hour front desk, room service and organizing tours for guests.", "Complete with a private bathroom equipped with a bath and a hair dryer, guest rooms at the guesthouse have a flat-screen TV and air conditioning, and certain rooms include a seating area. The rooms will provide guests with a closet and an electric tea pot.", "Veyvah Inn Maldives offers a continental or American breakfast.", "We have rooms starting from $45 per night for couples. Chat with us to check prices, availability and make reservation."],
  },
  {
    title: 'Snorkeling',
    url: '/snorkeling',
    photo: "./images/snorkeling.jpg",
    description:
      'Snorkel into the great coral reefs of beautiful Veyvah. Swim with turtles ' +
'and have fun of fish feeding, take under water photos with turtles and many ' +
'other marine life such as sharks, lion fish, sea urchins and many more. ' +
'Snorkeling gear is provided free of charge. Traveling time for most of snorkel ' +
'point will not be more than 15 minutes.',
    price: 60,
  },
  {
    title: 'Surfing',
    url: '/surfing',
    photo: "./images/surfing.jpg",
    description:
      "The central atolls of the Maldives enjoy a richness of moderate-sized, " +
      "perfect reefbreaks, most of which are righthanders. The central atolls " +
      "are a perfect area with limited crowds, as access to the majority of " +
      "the waves is by charter boat alone.",
    price: 60,
  },
  {
    title: 'Sandbank Tour',
    url: '/sandbank',
    photo: "./images/sandbank.jpg",
    description:
      'What better way to enjoy the beauty of the white sandy beaches and the ' +
'crystal clear oceans than walking on the beautiful and unique sand banks in the ' +
'Maldivian sea. Enjoy the scenery as we take you to the beautiful and enticing ' +
'sand banks near Veyvah Island. Sand banks are a bed of sand in a small island ' +
'from which you can experience the most clean and beautiful scenes of the ' +
'Maldivian ocean.',
  price: 60,
  },
  {
    title: 'Fishing',
    url: '/fishing',
    photo: "./images/fishing.jpg",
    description:
      'We allow you to empathize with the Maldivian customs and culture by experiencing one of the trademark industries of Maldives, the fishing industry. Embark on an adventure into the grand sea filled with plentiful fishes with the equipment provided, and have a fun time.',
    price: 60,
  },
  {
    title: 'Dolphin Trip',
    url: '/dolphin',
    photo: './images/dolphin.jpg',
    description: "Set cruise on a small speed boat to experience and meet the dolphins of the " +
"Maldives seas. Veyvah is known for having a very high potential of" +
"being oble to encounter dolphins ond many other morine life. The beautiful" +
"seas of Maldives is an eden for dolphin and marine life lovers, the oceans" +
"are as lively as it is beautiful.",
    price: 60,
  },
  {
    title: 'Picnic Island',
    url: '/picnic',
    photo: './images/picnic.jpg',
    description: "One of the most favourite trips is full day picnic island visit, " +
"including BBQ lunch on the beach. There is amazing snorkeling opportunity right " +
"from the beach, perfect even for beginners in snorkeling as there is no deep water. " +
"You can find wonderful big living coral trees.",
    price: 90,
  },
  {
    title: 'Special Dinner',
    url: '/dinner',
    photo: './images/dinner.jpg',
    description: "Want to spend a romantic night with a candle light dinner " +
"in an open air scenery? We offer to set up an amazing dinner night for you " +
"and your beloved one in the clean white beaches of Veyvah Island.",
    price: 25,
  },
];

import io from 'socket.io-client';

const socket = io('https://veyvah.in:8080', {secure:true});
//const socket = io('http://localhost:8080', {secure:false});

var events_registered = [];

socket.on("connect", () => {
  console.log('connected to backend, id: ', socket.id);
});

function define_event_handler(event_, cb) {
  //if (!events_registered.includes(event_)) {
    socket.on(event_, message => {
      cb(message)
    });
    events_registered.push(event_);
  //}
}

function remove_event(event_) {
  console.log('removing listener')
  socket.off(event_);
}

function emit_data(event_, data) {
  socket.emit(event_, data)
}

export { define_event_handler, emit_data, remove_event };
